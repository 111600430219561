import camelCase from 'lodash/camelCase';
import queryString from 'query-string';
import React from 'react';
import { Equipment } from '../../../interfaces/customer-api';
import { hasIconForEquipmentType } from '../../equipment-icons';
import { useOvermindState } from '../../state';
import { cns } from '../../utils';
import { EquipmentIcon } from '../EquipmentIcon/EquipmentIcon';
import { Link } from '../Link/Link';
import css from './MyEquipmentWidget.module.scss';
import KalmarLogo from '../../assets/kalmar-logo-new-round.svg';
import { Tooltip } from '../Tooltip/Tooltip';

interface EquipmentTypeCounts {
    [key: string]: number;
}

function getEquipmentCategories(equipment: Equipment[]) {
    // Count 'Other' equipment separately, as it should be shown last
    let otherCount = 0;
    const eqTypeCounts: EquipmentTypeCounts = equipment.reduce<EquipmentTypeCounts>(
        (acc: EquipmentTypeCounts, equ: Equipment) => {
            const label = hasIconForEquipmentType(equ.type) && equ.type;

            if (label) {
                acc[label] = acc[label] ? acc[label] + 1 : 1;
            } else {
                otherCount++;
            }
            return acc;
        },
        {},
    );

    const countElements = Object.entries(eqTypeCounts).map(([type, count], key) => (
        // The equipment types do not have translations
        <Tooltip title={type} placement="bottom" key={key}>
            <div>
                <Link
                    id={`my-equipment-${type}-button`}
                    key={type}
                    className={cns(css.equipmentType, camelCase(type))}
                    to={`/my-equipment?${queryString.stringify({ search: type })}`}
                >
                    <EquipmentIcon type={type} />
                    <span className={css.count}>{count}</span>
                </Link>
            </div>
        </Tooltip>
    ));

    if (otherCount > 0) {
        countElements.push(
            <Tooltip
                // The equipment types do not have translations
                title={'Other Equipment'}
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -16],
                            },
                        },
                    ],
                }}
                placement="bottom"
            >
                <div>
                    <Link
                        id={`my-equipment-other-button`}
                        key="other"
                        className={cns(css.equipmentType, css.otherType)}
                        to={`/my-equipment?${queryString.stringify({ search: 'Other Equipment' })}`}
                    >
                        <KalmarLogo />
                        <span className={css.count}>{otherCount}</span>
                    </Link>
                </div>
            </Tooltip>,
        );
    }

    return countElements;
}

export const MyEquipmentWidget: React.FunctionComponent = () => {
    const { siteEquipment } = useOvermindState();
    return (
        <div className={css.myEquipmentWidget}>
            <div className={css.equipmentTypeContainer}>{getEquipmentCategories(siteEquipment)}</div>
        </div>
    );
};
