import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowRight from '../../assets/arrow-right.svg';
import { useOvermindState } from '../../state';
import { cns, getCaseManagementLink, isLoading } from '../../utils';
import { Spinner } from '../Spinner/Spinner';
import css from './KalmarInsightWidget.module.scss';

export const KalmarInsightWidget: React.FunctionComponent = () => {
    const { t } = useTranslation('KalmarInsightWidget');
    const { insightUserCount, insightLicenseCounts, loadingStates, userAccess } = useOvermindState();

    const shouldShowUserManagementLink = userAccess.kalmarInsight.create;

    return (
        <div className={css.kalmarInsightWidget}>
            <div className={css.insightUsers}>
                <h3>{t('Number of MyKalmar Insight users')}</h3>
                <div className={css.count}>
                    {isLoading(loadingStates.insightUserCount) ? <Spinner size={3} /> : insightUserCount ?? t('N/A')}
                </div>
                {shouldShowUserManagementLink && (
                    <a
                        href={getCaseManagementLink('/s/user-management')}
                        className={css.manageUsersLink}
                        id="manage-insight-users-button"
                    >
                        {t('Manage users')} <ArrowRight />
                    </a>
                )}
            </div>
            <div className={css.licenses}>
                <div className={css.license}>
                    <h4 className={css.title}>{t('Valid license')}</h4>
                    <span className={cns(css.number, css.valid)}>{insightLicenseCounts.valid}</span>
                    <span className={css.subtitle}>{t('equipment')}</span>
                </div>
                <div className={css.license}>
                    <h4 className={css.title}>{t('Expired license')}</h4>
                    <span className={cns(css.number, css.expired)}>{insightLicenseCounts.expired}</span>
                    <span className={css.subtitle}>{t('equipment')}</span>
                </div>
                <div className={css.license}>
                    <h4 className={css.title}>{t('License expiring soon')}</h4>
                    <span className={cns(css.number, css.expiring)}>{insightLicenseCounts.expiring}</span>
                    <span className={css.subtitle}>{t('equipment')}</span>
                </div>
            </div>
        </div>
    );
};
