import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../interfaces/internal';
import { useActions, useOvermindState } from '../../state';
import css from './LanguageSelectorModal.module.scss';
import { cns } from '../../utils';

interface LanguageSelectorModalProps {
    onClose: () => void;
}
export const LanguageSelectorModal = React.forwardRef<HTMLDivElement, LanguageSelectorModalProps>(
    (props: LanguageSelectorModalProps, ref) => {
        const { setLanguage } = useActions();
        const { language } = useOvermindState();
        const { t } = useTranslation('LanguageSelector');
        const [selectedLanguage, setSelectedLanguage] = useState(language);

        const onSave = () => {
            setLanguage(selectedLanguage);
            props.onClose();
        };

        const options = [
            { label: 'English', value: Language.English },
            { label: 'Deutsch', value: Language.German },
            { label: 'Español', value: Language.Spanish },
            { label: 'Français', value: Language.French },
            { label: '中文', value: Language.Chinese },
        ];

        return (
            <div id="language-selector-modal" className={css.languageSelector} tabIndex={-1} ref={ref}>
                <h6 className={css.title}>{t('Select language')}</h6>
                <RadioGroup
                    value={selectedLanguage}
                    onChange={(event) => {
                        setSelectedLanguage(event.target.value as Language);
                    }}
                >
                    {options.map(({ label, value }) => (
                        <div key={value} className={cns(css.row)}>
                            <FormControlLabel
                                key={value}
                                value={value}
                                control={<Radio color="primary" size="medium" className={css.radio} />}
                                label={label}
                                classes={{ root: css.option, label: css.label }}
                            />
                        </div>
                    ))}
                </RadioGroup>
                <div className={css.controls}>
                    <Button className={css.button} color="primary" onClick={props.onClose}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={language === selectedLanguage}
                        className={css.button}
                        color="primary"
                        onClick={onSave}
                    >
                        {t('OK')}
                    </Button>
                </div>
            </div>
        );
    },
);
